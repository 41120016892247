<script setup lang="ts">
import { defineProps, withDefaults } from "vue";
import { type DataObjectProgress } from "o365.pwa.modules.client.steps.DataObjectProgress.ts";
import DataObjectProgressOffline from "o365.pwa.vue.components.steps.DataObjectProgressOffline.vue";
import DataObjectProgressOnline from "o365.pwa.vue.components.steps.DataObjectProgressOnline.vue";

interface IProps {
  syncStepProgress: DataObjectProgress;
  currentStep: boolean;
}

const props = withDefaults(defineProps<IProps>(), {
  currentStep: false,
});

</script>

<script lang="ts">
export default {
  name: "DataObjectProgress",
};
</script>

<template>
  <DataObjectProgressOffline v-if="props.syncStepProgress.syncType === 'OFFLINE-SYNC'"
    :syncStepProgress="props.syncStepProgress" :currentStep="props.currentStep" />
  <DataObjectProgressOnline v-else-if="props.syncStepProgress.syncType === 'ONLINE-SYNC'"
    :syncStepProgress="props.syncStepProgress" :currentStep="props.currentStep" />
</template>

